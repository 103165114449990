@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$primary: #006C4A;
$secondary: #006D43;
$success: #1C6C30;
$info: #73FBBF;
$warning: #BA1A1A;
$danger: #FFDAD6;
$light: #F4FFF6;
$dark: #002114;
$navbar-brand-color: #C9EFC7;
$financial-section-bg-color: #002114;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $navbar-brand-color,
        "dark": $dark,
);

$navbar-light-color: $navbar-brand-color;
$navbar-light-active-color: $navbar-brand-color;
//$navbar-light-active-color: #738A78;
$navbar-light-hover-color: #99BCA0;

$card-color: $navbar-brand-color;
$card-bg: $financial-section-bg-color;
$card-border-width: 0;
$card-border-radius: 18px;

$btn-color: $financial-section-bg-color;
$btn-border-radius: 18px;

$headings-font-family: "Montserrat", sans-serif;
$headings-font-style: normal;

$navbar-brand-font-size: 1rem * 1.5 !default;

$accordion-border-color: $financial-section-bg-color;
$accordion-border-radius: 18px;
$accordion-inner-border-radius: 18px;

$accordion-bg: $navbar-brand-color;
$accordion-button-active-bg: $info;

$navbar-toggler-transition: all .15s ease-in-out;

$input-border-radius: 18px;

@import "../../node_modules/bootstrap/scss/bootstrap";

$bootstrap-icons-font-dir: "../../node_modules/bootstrap-icons/font/fonts";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons";

.navbar-brand {
  color: $navbar-brand-color;
}

.navbar-brand:hover {
  color: $navbar-brand-color;
}

.brand-img {
  height: auto;
  width: 56px;
  margin: -5px;
}

.custom-navbar {
  padding: .75rem 0;
  background-color: $financial-section-bg-color;
  background-image: url('../imgs/finance_pattern.svg');
  background-size: 256px;
  background-repeat: repeat;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar-onscroll {
  background-color: lighten($financial-section-bg-color, 0.8);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 108, 74, 0.15) !important;
}

.bd-gutter {
  --bs-gutter-x: 3rem;
}

.financial-section {
  background-color: $financial-section-bg-color;
  background-image: url('../imgs/finance_pattern.svg');
  background-size: 256px;
  background-repeat: repeat;
  padding: 3rem;
  color: $navbar-brand-color;
}

.app-preview {
  height: auto;
  width: 240px;
}

.app-preview-feature {
  height: auto;
  max-width: 310.50px;
}

.rounded-18 {
  border-radius: 18px !important
}

.bd-title {
  font-size: calc(1.425rem + 2.1vw);
}

.bd-lead {
  font-size: calc(1.275rem + .3vw);
  font-weight: 300;
}

@font-face {
  font-family: 'CustomIcons';
  src: url('../fonts/CustomIcons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: 'CustomIcons', sans-serif;
  font-size: 30px;
  line-height: 1;
  speak: none;
  display: inline-block;
  text-align: center;
  text-decoration: inherit;
}

.icon-robot::before {
  content: '\e800';
}

.card-icon {
  font-family: 'CustomIcons', sans-serif;
  font-size: 24px;
  line-height: 1;
  speak: none;
  display: inline-block;
  text-align: center;
  text-decoration: inherit;
}

html {
  scroll-padding-top: 68px;
}

body {
  background-color: $light;
}

.a-link-custom {
  color: $navbar-brand-color;
}

// Custom NAVBAR ICON ANIMATION

.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $navbar-brand-color;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}


/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
  background: $navbar-brand-color;
}